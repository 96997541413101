<template>
    <div>
        <div>
            <b-form ref="form" @submit.prevent="submit">
                <!-- id, active -->
                <b-form-group
                        class="mb-0"
                        label-cols="6"
                        :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                        label-size=""
                        label-class="pt-0"
                >
                    <b-form-checkbox
                            id="active"
                            class="float-right"
                            switch
                            v-model="record.active"
                            name="active"
                            value="true"
                            unchecked-value="false"
                    >
                        {{activeText}}
                    </b-form-checkbox>
                </b-form-group>
                <!-- meta -->
                <b-form-group
                        :label="$tc('form.meta.data', 0)"
                        class="mb-0 group-title p-0"
                        label-class="font-weight-bold"
                        label-align-sm="left"
                        label-cols-sm="12"
                        label-size=""
                >
                    <b-form-row>
                        <!-- name -->
                        <b-form-group
                                id="group-meta.name"
                                class="col-sm-12 col-md-6"
                                label-class="mb-0"
                                :label="$t('form.name')"
                                label-for="meta.name"
                                description=""
                                :state="valid_metaName"
                        >
                            <b-form-input
                                    id="meta.name"
                                    v-model="record.meta.name"
                                    :state="valid_metaName"
                                    required
                                    :placeholder="$t('form.required_field')"
                            ></b-form-input>
                        </b-form-group>
                        <!-- name_full -->
                        <b-form-group
                                id="group-meta.name_full"
                                class="col-sm-12 col-md-6"
                                label-class="mb-0"
                                :label="$t('form.name_full')"
                                label-for="meta.name_full"
                                description=""
                                :state="valid_metaNameFull"
                        >
                            <b-form-input
                                    id="meta.name_full"
                                    :state="valid_metaNameFull"
                                    v-model="record.meta.name_full"
                                    required
                                    :placeholder="$t('form.required_field')"
                            ></b-form-input>
                        </b-form-group>
                    </b-form-row>
                </b-form-group>
                <!-- content -->
                <b-form-group
                        :label="$t('form.content')"
                        class="mb-0 group-title p-0"
                        label-class="font-weight-bold"
                        label-align-sm="left"
                        label-cols-sm="12"
                        label-size=""
                >
                    <b-form-row>
                        <!-- iban -->
                        <b-form-group
                                id="group-content.iban"
                                class="col-sm-6 col-md-3"
                                label-class="mb-0"
                                :label="$t('register.iban')"
                                label-for="content.iban"
                                description=""
                        >
                            <b-form-input
                                    id="content.iban"
                                    v-model="record.content.iban"
                            ></b-form-input>
                        </b-form-group>
                        <!-- bic -->
                        <b-form-group
                                id="group-content.bic"
                                class="col-sm-6 col-md-3"
                                label-class="mb-0"
                                :label="$t('register.bic')"
                                label-for="content.bic"
                                description=""
                        >
                            <b-form-input
                                    id="content.bic"
                                    v-model="record.content.bic"
                            ></b-form-input>
                        </b-form-group>
                        <!-- url -->
                        <b-form-group
                                id="group-content.url"
                                class="col-sm-6 col-md-3"
                                label-class="mb-0"
                                :label="$t('form.url')"
                                label-for="content.url"
                                description=""
                        >
                            <b-form-input
                                    id="content.url"
                                    type="url"
                                    v-model="record.content.url"
                            ></b-form-input>
                        </b-form-group>
                        <!-- order -->
                        <b-form-group
                                id="group-order"
                                class="col-sm-6 col-md-3"
                                label-class="mb-0"
                                :label="$t('form.order')"
                                label-for="order"
                                description=""
                        >
                            <b-form-input
                                    id="order"
                                    type="number"
                                    v-model="record.order"
                            ></b-form-input>
                        </b-form-group>
                    </b-form-row>
                </b-form-group>
                <!-- foreign_data -->
                <b-form-group
                        class="mb-0 group-title p-0"
                >
                    <b-form-row>
                        <!-- state_id -->
                        <b-form-group
                                id="group-continent_id"
                                class="col-sm-12 col-md-6"
                                label-class="mb-0"
                                :label="$tc('register.state', 1)"
                                label-for="state_id"
                                description=""
                                :state="valid_state"
                        >
                            <b-form-select
                                    id="state_id"
                                    v-model="record.state_id"
                                    :options="states"
                                    required
                                    :state="valid_state"
                            >
                            </b-form-select>
                        </b-form-group>
                    </b-form-row>
                </b-form-group>
                <!--  -->
            </b-form>
        </div>
    </div>
</template>

<script>
    import utilsGeneral from "../../../utils/utilsGeneral";
// @group Form.REGISTER
export default {
        name: "FormBank",
        components: {},
        props: {
            form: {
                valid: false,
                record: {},
            },
            data: {},
            modalBus: Object,
        },
        data() {
            return {
                valid: null,
                record: {
                    id: 0,
                    active: false,
                    order: 0,
                    state_id: null,
                    meta: {
                        name: '',
                        name_full: ''
                    },
                    content: {
                        iban: '',
                        bic: '',
                        url: '',
                    },
                },
                states: [],
            }
        },
        mounted() {
            this.modalBus.$on('submit', this.submit);
            // <-- set foreign data -->
            this.setStates();
            // <--  -->
            if (this.form.record != null) {
                this.$set(this, 'record', this.form.record);
            }
        },
        methods: {
            // <-- set selects data -->
            setStates() {
                let states = [
                    {value: null, text: '/', disabled: false},
                ];
                let meta = false;
                let locale = {};
                this.data.states.forEach((state, index) => {
                    meta = false;
                    locale = state.locale[this.$i18n.locale];
                    // <-- if missing locale data -->
                    if (locale === undefined) {
                        meta = true;
                        locale = state.meta;
                    }
                    states.push(
                        {
                            value: state.id,
                            text: locale.name + (meta ? this.$t('form.meta.message', {msg: this.$t('form.name').toLowerCase()}) : ''),
                            disabled: false
                        }
                    );
                });
                // <--  -->
                this.$set(this, 'states', states);
            },
            // <-- form -->
            submit() {
                if (this.$refs.form === undefined) {
                    return;
                }
                this.valid = this.$refs.form.checkValidity();

                this.$set(this.form, 'record', this.record);
                this.$set(this.form, 'valid', this.valid);
            }
        },
        computed: {
            activeText: {
                get() {
                    return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                        ? this.$t('form.inactive')
                        : this.$t('form.active');
                }
            },
            // <-- meta -->
            valid_metaName() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.meta.name.length > 0 ? null : false;
            },
            valid_metaNameFull() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.meta.name_full.length > 0 ? null : false;
            },
            // <-- valid foreign data -->
            valid_state() {
                if (this.valid === null) {
                    return null;
                }
                return this.record.state_id > 0 ? null : false;
            },
        },
        filters: {},
        watch: {}
    }
</script>

<style scoped>
    .group-title {
        /*color: gray;*/
    }
</style>
